import { SignalService } from './../../signals/signals.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConsts } from '../../constants/system.constants';
import { BaseClass } from '../../../base-class';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(private baseClass: BaseClass, private httpBackend: HttpBackend, private signalService: SignalService) {
  }
  loadAppConfig(): Promise<boolean> {
    this.signalService.refreshData();
    // bypass HTTP interceptors by using HttpBackend
    return Promise.resolve(false)
    if (this.baseClass.checkPlatform()) {
      const http = new HttpClient(this.httpBackend);

      return (
        http.get<any>(`appconfig.json`)
          // convert to Promise per Angular's `useFactory` requirement (not officially documented)
          .toPromise()
          .then(response => {
            // using a class factory to keep AppInfo class getters in place
            AppConsts.appBaseUrl = response.apiUrl;
          })
          // returning `true` to satisfy `useFactory` contract (not officially documented)
          .then(_ => Promise.resolve(true))
          .catch(error => {
            console.error('Error loading info', error);
            return Promise.resolve(false);
          })
      );
    }
    else return Promise.resolve(false)
  }
}
