import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class BhvService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }
  getLinkRedirect(params: any) {
    return this.http
      .post(`${this.apiDefault}/bhv`, params)
      .pipe(map((res: any) => res));
  }
}
