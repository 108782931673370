import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../storage/local.service';
import { BaseService } from '../services/base/base.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor extends BaseService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isPlatformBrowser(this.platformId)) return throwError(null);

    const token: any = this.localStorageService.getItem('token');
    const refreshToken: any = this.localStorageService.getItem('refreshToken');

    return next.handle(this.addAuthorizationHeader(req, token)).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && this.isTokenRefreshError(err, req)) {
          return this.handle401Error(req, next);
        } else {
          // this.logoutAndRedirect(err);
          return throwError(err);
        }
      })
    );
  }
  private isTokenRefreshError(error: HttpErrorResponse, req: HttpRequest<any>): boolean {
    return error.status === 401 && !req.url.includes('RefreshToken');
  }
  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const referralCode = this.cookieService.get('referralCode');
    if (token) {
      let key = this.localStorageService.getItem('loginMode');
      const headers = request.headers.set('X-CBH-PLATCODE', key ? key : '');
      let setHeaders: any = { Authorization: `Bearer ${token}` };
      if (referralCode) {
        setHeaders.referralCode = referralCode
      }
      return request.clone({
        setHeaders,
        headers: headers,
      });
    }
    if (referralCode) {
      return request.clone({
        setHeaders: { referralCode: referralCode },
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.localStorageService.getItem('refreshToken');
      if (refreshToken) {
        return this.refreshToken(refreshToken).pipe(
          switchMap((res: any) => {
            console.log(res, 'res');
            let token = res?.data?.jwToken;
            this.isRefreshing = false;
            this.localStorageService.setItem('token', token);
            this.localStorageService.setItem('refreshToken', res?.data?.refreshToken);
            this.refreshTokenSubject.next(token);
            return next.handle(this.addAuthorizationHeader(request, token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            console.log(err, 'err');

            this.logoutAndRedirect(err);
            return throwError(err);
          })
        );
      } else {
        // this.logoutAndRedirect(null);
        return throwError('No refresh token available');
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthorizationHeader(request, token));
        })
      );
    }
  }

  private refreshToken(refreshToken: string): Observable<any> {
    let req: any = {
      refreshToken: refreshToken,
    };
    return this.http.post(`${this.apiDefault}/Account/RefreshToken`, req)
  }

  private logoutAndRedirect(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    let tempMethod = this.localStorageService.getItem('loginMethod');
    this.localStorageService.clear();
    if (tempMethod) this.localStorageService.setItem('loginMethod', tempMethod);
    window["ReactNativeWebView"]?.postMessage(JSON.stringify({
      type: "GO_BACK",
    }));
    this.router.navigate(['/']);
    // Implement your redirection logic here
    return throwError(err);
  }
}
