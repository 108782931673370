import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors';
import { RequestResponseHandlerInterceptor } from './shared/interceptors/request-response-handler.interceptor';
import { AppConfigService } from './shared/services/app-config/app-config.service';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
export function initializeApp(appConfigService: AppConfigService) {
  return () => {
    return appConfigService.loadAppConfig();
  }
}
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}
import { provideEnvironmentNgxMask } from 'ngx-mask'
import { registerLocaleData } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { MBHandlerDirective } from './shared/directive/MB-handle.directive';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { NoopScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    provideAnimations(), // required animations providers
    provideToastr({
      timeOut: 3000,
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      easing: 'ease-in',
      easeTime: 300,
      positionClass: 'toast-bottom-center',
    }), // Toastr providers
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestResponseHandlerInterceptor,
      multi: true
    },
    importProvidersFrom(MBHandlerDirective),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        restoreFocus: false,
      }
    },
  ]
};
